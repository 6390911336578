import React from "react"

const LoopingGifWhite = () => {
    return (
        <img
        src="https://d1g9ggdtg3kjm9.cloudfront.net/newimg15042023/done_properly_230413-50.gif"
        alt="moving img"
        style={{ maxWidth: "100%" }}
        />
    )
}

export default LoopingGifWhite