import React from "react"
import { Box } from "@mui/material"

const UpcomingEvents = ({ themeMode }) => {
    // const [eventsTab, setEventsTab] = React.useState(1)
    return (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          placeholder
        </Box>
    )
}

export default UpcomingEvents